export const openCategoriesMenu = () => {
  document.getElementById('deactivedBackground')?.classList.add('lightColor');
  document.getElementById('mainCategoryMenu')?.classList.add('visible');

  document.getElementById('mainCategoryMenu')?.classList.remove('notVisible');

  document.getElementById('bodyElement')?.classList.add('lockedScroll');

  document.getElementById('deactivedBackground')?.classList.add('visible');

  document
    .getElementById('deactivedBackground')
    ?.classList.remove('notVisible');
};

export const closeCategoriesMenu = () => {
  document.getElementById('mainCategoryMenu')?.classList.remove('visible');

  document.getElementById('mainCategoryMenu')?.classList.add('notVisible');

  document.getElementById('deactivedBackground')?.classList.remove('visible');

  document.getElementById('bodyElement')?.classList.remove('lockedScroll');

  document.getElementById('deactivedBackground')?.classList.add('notVisible');
  document
    .getElementById('deactivedBackground')
    ?.classList.remove('lightColor');
  closeSubCategoriesMenu();
  closeSearch();
};

export const closeSubCategoriesMenu = () => {
  document.getElementById('subCategoryMenu')?.classList.remove('visible');

  document.getElementById('subCategoryMenu')?.classList.add('notVisible');
};
export const openSubCategoriesMenu = () => {
  document.getElementById('subCategoryMenu')?.classList.add('visible');

  document.getElementById('subCategoryMenu')?.classList.remove('notVisible');
};

export const showProviderTab = () => {
  document?.getElementById('cookieModelContainer')?.classList.remove('hide');
  document
    ?.getElementById('providerTabContent')
    ?.classList.add('wb-tab-content--selected');
  document?.getElementById('tabs')?.setAttribute('selected', 'provider');
};

export const showLegalNoticeTab = () => {
  document?.getElementById('cookieModelContainer')?.classList.remove('hide');
  document
    ?.getElementById('legalNoticeTabContent')
    ?.classList.add('wb-tab-content--selected');
  document?.getElementById('tabs')?.setAttribute('selected', 'legalNotice');
};

export const showDataPrivacyTab = () => {
  document?.getElementById('cookieModelContainer')?.classList.remove('hide');
  document
    ?.getElementById('dataPrivacyTabContent')
    ?.classList.add('wb-tab-content--selected');
  document?.getElementById('tabs')?.setAttribute('selected', 'dataPrivacy');
};

export const showSettingsTab = () => {
  document?.getElementById('cookieModelContainer')?.classList.remove('hide');
  document
    ?.getElementById('settingsTabContent')
    ?.classList.add('wb-tab-content--selected');
  document?.getElementById('tabs')?.setAttribute('selected', 'settings');
};

export const closeSearch = () => {
  document
    .getElementsByClassName('.wb-header__logo-container')[0]
    ?.classList.remove('foreground');
  document.getElementById('searchContainer')?.classList.remove('visible');

  document.getElementById('searchContainer')?.classList.add('notVisible');

  document.getElementById('deactivedBackground')?.classList.remove('visible');

  document.getElementById('deactivedBackground')?.classList.add('notVisible');

  document.getElementById('deactivedBackground')?.classList.remove('darkColor');
};
