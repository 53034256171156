import './CategoryList.css';
import adBlueImage from '../../../resource/image/adBlueSmall.jpg';
import gearImage from '../../../resource/image/GetriebeSmall.jpg';
import coolantImage from '../../../resource/image/KühlantSmall.jpg';
import engineImage from '../../../resource/image/MotorölSmall.jpg';

import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function CategoryList(props: { description: string }) {
  const { t, i18n } = useTranslation();
  const KatList = [
    {
      image: engineImage,
      URL: '/category/engine-oil/',
      title: t('EngineOil'),
      class: 'Hydraulikol',
      description: 'EngineOil',
    },
    {
      image: gearImage,
      URL: '/category/gear-oil/',
      title: t('GearOil'),
      class: 'Kraftstoff',
      description: 'HydraulicFluid',
    },
    {
      image: coolantImage,
      URL: '/category/coolant/',
      title: t('Coolant'),
      class: 'Getriebeol',
      description: 'Coolant',
    },
    {
      image: adBlueImage,
      URL: '/category/NOx-reducing-agent/',
      title: t('AdBlue'),
      class: 'Bremsflussigkeit',
      description: 'AdBlue',
    },
  ];

  return (
    <div className='width100 Title'>
      <section className='wb-padding-vertical-s'>
        <div>
          <div className='wb-type-label wb-margin-bottom-xxs text-white'>
            {t('Interested')}
          </div>
          <hr className='--wb-color-gunmetal Divider'></hr>
        </div>
        <h1 className='wb-type-heading-l text-white TopCategoryTitle'>
          {t('TopCategories')}
        </h1>
      </section>

      <div className='wb-grid'>
        <div className='wb-grid-row'>
          {props.description !== 'EngineOil' && (
            <div className='wb-grid-col-mq1-12 wb-grid-col-mq2-4 wb-grid-col-mq3-4 wb-grid-col-mq4-4 wb-grid-col-mq5-4 wb-grid-col-mq6-4 '>
              <Link
                to={
                  i18n.language === 'de'
                    ? KatList[0].URL + 'de'
                    : KatList[0].URL + 'en'
                }
                className=' text-white list-anchor'
              >
                <img
                  src={KatList[0].image}
                  className='MBBeVoTeaser__image'
                ></img>
                <section className='wb-padding-vertical-s'>
                  <h1 className='wb-type-heading-m TopCategoryDescription'>
                    {' '}
                    {KatList[0].title}
                  </h1>
                </section>
              </Link>
            </div>
          )}
          {props.description !== 'GearOil' && (
            <div className='wb-grid-col-mq1-12 wb-grid-col-mq2-4 wb-grid-col-mq3-4 wb-grid-col-mq4-4 wb-grid-col-mq5-4 wb-grid-col-mq6-4'>
              <Link
                to={
                  i18n.language === 'de'
                    ? KatList[1].URL + 'de'
                    : KatList[1].URL + 'en'
                }
                className=' text-white list-anchor'
              >
                <img
                  src={KatList[1].image}
                  className='MBBeVoTeaser__image'
                ></img>
                <section className='wb-padding-vertical-s'>
                  <h1 className='wb-type-heading-m TopCategoryDescription'>
                    {' '}
                    {KatList[1].title}
                  </h1>
                </section>
              </Link>
            </div>
          )}

          {props.description !== 'Coolant' && (
            <div className='wb-grid-col-mq1-12 wb-grid-col-mq2-4 wb-grid-col-mq3-4 wb-grid-col-mq4-4 wb-grid-col-mq5-4 wb-grid-col-mq6-4'>
              <Link
                to={
                  i18n.language === 'de'
                    ? KatList[2].URL + 'de'
                    : KatList[2].URL + 'en'
                }
                className=' text-white list-anchor'
              >
                <img
                  src={KatList[2].image}
                  className='MBBeVoTeaser__image'
                ></img>
                <section className='wb-padding-vertical-s'>
                  <h1 className='wb-type-heading-m TopCategoryDescription'>
                    {' '}
                    {KatList[2].title}
                  </h1>
                </section>
              </Link>
            </div>
          )}

          {(props.description === 'EngineOil' ||
            props.description === 'GearOil' ||
            props.description === 'Coolant') && (
            <div className='wb-grid-col-mq1-12 wb-grid-col-mq2-4 wb-grid-col-mq3-4 wb-grid-col-mq4-4 wb-grid-col-mq5-4 wb-grid-col-mq6-4 '>
              <Link
                to={
                  i18n.language === 'de'
                    ? KatList[3].URL + 'de'
                    : KatList[3].URL + 'en'
                }
                className=' text-white list-anchor'
              >
                <img
                  src={KatList[3].image}
                  className='MBBeVoTeaser__image'
                ></img>
                <section className='wb-padding-vertical-s Title'>
                  <h1 className='wb-type-heading-m Title'>
                    {' '}
                    {KatList[3].title}
                  </h1>
                </section>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
