export interface IContact {
  email: string;
  title: string;
  message: string;
  locale: string;
}

export const emptyContactObject = {
  email: '',
  title: '',
  message: '',
  locale: 'de'
};
