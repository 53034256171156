import './OilFinder.css';
import ComingSoon from '../general/comingSoon/ComingSoon';

/**
 * Main page (landing page or home page) component.
 *
 * @returns
 */

export default function OilFinder() {
  return (
    <div>
      <ComingSoon></ComingSoon>
    </div>
  );
}
