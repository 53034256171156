import oil from '../../../../resource/image/oilPlaceholder.jpg';
import car from '../../../../resource/image/carPlaceholder.png';
import './MBBeVoDesc.css';
import MBBeVoTeaser from '../MBBeVoTeaser/MBBeVoTeaser';
import MBBeVoContent from '../MBBeVoContent/MBBeVoContent';
import { useTranslation } from 'react-i18next';

const MBBeVoDesc = () => {
  const { t } = useTranslation();
  const teaserContent = [
    {
      title: t('MbOgOil'),
      subTitle: t('MbChoice'),
      URL: '#1',
      image: oil,
    },
    {
      title: t('OperatingInstructions'),
      subTitle: t('OperatingInstructionsContent'),
      URL: '#2',
      image: car,
    },
  ];

  return (
    <div>
      <div style={{ marginTop: '48px' }} className='wb-grid-container'>
        <div className='wb-grid-row'>
          <MBBeVoContent />
        </div>
      </div>
      {/* <div style={{ marginTop: '72px' }} className='wb-grid-container'>
        <div className='wb-grid-row'>
          <MBBeVoTeaser
            title={teaserContent[0].title}
            subTitle={teaserContent[0].subTitle}
            URL={teaserContent[0].URL}
            image={teaserContent[0].image}
          />

          <MBBeVoTeaser
            title={teaserContent[1].title}
            subTitle={teaserContent[1].subTitle}
            URL={teaserContent[1].URL}
            image={teaserContent[1].image}
          />
        </div>
      </div> */}
    </div>
  );
};

export default MBBeVoDesc;
