import React from 'react';
import { Icon } from '@workbench/core';
//import { WbContentSlider } from "@workbench/react";
import { createStyles, makeStyles } from '@material-ui/core';
import IconCard from '../MBBeVoIconCard/MBBeVoIconCard';
import { MBBeVoContentSliderWeb } from '../MBBeVoContentSliderWeb/MBBeVoContentSliderWeb';
import { ContentSlider } from '../MBBeVoContentSliderWeb/ContentSlider';
import MBBeVoContentSliderMobile from '../MBBeVoContentSliderMobile/MBBeVoContentSliderMobile';
import './MBBeVoContentSlider.css';

import { CATEGORY_PROPS } from '../../../../globals/Constants';

const MBBeVoContentSlider = () => {
  return (
    <div className=''>
      {/* Slider for Web and Tablets >= 768px */}
      <div className='MBBeVoContentSlider-web-tab'>
        <ContentSlider content={CATEGORY_PROPS}></ContentSlider>
      </div>
      {/* Slider for mobile devices < 768px */}
      <div className='MBBeVoContentSlider-mobile MBBeVoContentSlider-mobile-space'>
        <MBBeVoContentSliderMobile
          content={CATEGORY_PROPS}
        ></MBBeVoContentSliderMobile>
      </div>
    </div>
  );
};

export default MBBeVoContentSlider;
