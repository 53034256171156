/* eslint-disable */
import React from 'react';
import clsx from 'clsx';

import { Icon } from '@workbench/core';
import { createStyles, makeStyles } from '@material-ui/core';
import './IconNavigation.css';
import { WbIcon } from '@workbench/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// Styling
const useStyles = makeStyles(() =>
  createStyles({
    content: {
      color: 'white',
      textAlign: 'center',
    },
  })
);

export default function IconNavigation(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Link to={props.link}>
        <div
          class='iconNavigationContainer '
          onclick={`window.location.href=${props.link}`}
        >
          <div>
            <wb-tooltip-host
              open={props.open}
              position={props.position}
              class={clsx('tooltipContainer')}
            >
              <button
                class='roundButtonStyle wb-round-button wb-round-button--solid-level-0 wb-round-button--small'
                type='button'
              >
                <wb-icon
                  class='iconStyle'
                  name='bds/hotspot-active/24'
                ></wb-icon>
              </button>
              <wb-tooltip
                class={clsx('tooltipStyle', { ['noHover']: props.open })}
              >
                {t(props.description)}
                <Link class='activeLink' to={props.link}>
                  <WbIcon
                    class={clsx('tooltipIcon')}
                    name='bds/chevron-right/24'
                  ></WbIcon>
                </Link>
              </wb-tooltip>
            </wb-tooltip-host>
          </div>
        </div>
      </Link>
    </>
  );
}
