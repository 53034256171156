import { IProduct, emptyProductObject } from './IProduct';

export interface IProductSheet {
  sheetNumber: string;
  oldSheetNumber?: string;
  title: string;
  productCollection: IProduct[];
  category: string;
  titleDe: string;
  titleEn: string;
  titlePt: string;
  htmlDe: string;
  htmlEn: string;
  htmlPt: string;
  pdfEn: string;
  pdfDe: string;
  pdfPt: string;
  additionalInfoDe: string;
  additionalInfoEn: string;
  additionalInfoPt: string;
  publishDate: Date|string;
  privateLabels: Record<string, string>;
  [key: string]: string | undefined | Date | IProduct[] | Record<string, string> | any ;
}

export const emptyProductSheetObject = {
  sheetNumber: '',
  title: '',
  productCollection: [emptyProductObject],
  category: '',
  titleDe: '',
  titleEn: '',
  titlePt: '',
  htmlDe: '',
  htmlEn: '',
  htmlPt: '',
  pdfEn: '',
  pdfDe: '',
  pdfPt: '',
  additionalInfoDe: '',
  additionalInfoEn: '',
  additionalInfoPt: '',
  publishDate: new Date(),
  privateLabels: {},
};


export interface IColumn {
  key: keyof IProduct;
  check: (row: IProduct) => boolean;
  isLink?: boolean;
}