import axios from 'axios';
import { BASE_URL } from '../globals/Constants';
import { setCategoryString } from './Helper';
import { IContact } from '../interfaces/IContact';

export const getSheetsByCategory = (category: string) => {
  const categoryText = setCategoryString(category);
  return axios.get(`${BASE_URL}category/${categoryText}`);
};

export const getAllSheets = (category: string) => {
  return axios.get(`${BASE_URL}allSheetsByCategory/`);
};

export const getAllData = (category: string) => {
  return axios.get(`${BASE_URL}all/`);
};

export const getSheetNumbersByCategory = (category: string) => {
  const categoryText = setCategoryString(category);
  return axios.get(`${BASE_URL}categorySheetNumbers/${categoryText}`);
};

export const getSheetByNumber = (sheetNumber: string) => {
    sheetNumber = sheetNumber.replaceAll('_', '%20');
  return axios.get(`${BASE_URL}sheet/${sheetNumber}`);
};

export const getSearchResults = (text: string) => {
  return axios.get(`${BASE_URL}search/${text}`);
};

export const getProductsById = (productId: string) => {
  return axios.get(`${BASE_URL}product/${productId}`);
};

export async function sendContact(contactData: IContact) {
  const data = await axios.post(`${BASE_URL}contact`, contactData);
  return data;
}
