import motor from '../../../../resource/image/motorPlaceholder.jpg';
import './MBBeVoContent.css';
import { useTranslation, Trans } from 'react-i18next';

const MBBeVoContent = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='wb-grid-row'>
      <div className='wb-grid-col-mq1-12 wb-grid-col-mq2-12 wb-grid-col-mq3-6 wb-grid-col-mq4-6 wb-grid-col-mq5-6 wb-grid-col-mq6-6'>
        <p className='wb-type-heading-m MBBeVoContent__text MBBeVoContent__spaceBottom MBBeVoContent__title-top'>
          Mercedes-Benz Trucks Operating Fluids
        </p>
        <img src={motor} className='MBBeVoContent__image'></img>
      </div>

      <div className='wb-grid-col-mq1-12 wb-grid-col-mq2-12 wb-grid-col-mq3-6 wb-grid-col-mq4-6 wb-grid-col-mq5-6 wb-grid-col-mq6-6'>
        <p className='wb-type-heading-m MBBeVoContent__text  MBBeVoContent__title-bottom'>
          Mercedes-Benz Trucks Operating Fluids
        </p>
        <p className='contentText wb-type-copy-tertiary MBBeVoContent__text MBBeVoContent__text-bottom'>
          {t('IntroductionContent')}
        </p>
      </div>
    </div>
  );
};

export default MBBeVoContent;
