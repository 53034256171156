import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { getSheetByNumber } from '../../../utility/FetchAPI';
import { WbIcon } from '@workbench/react';

import { useTranslation } from 'react-i18next';
import { IProduct } from '../../../interfaces/IProduct';
import {
  emptyProductSheetObject,
  IProductSheet,
  IColumn,
} from '../../../interfaces/IProductSheet';
import { TABLE_HEADER } from '../../../globals/Constants';
import './SheetTable.css';

export const SheetTable = (props: {
  sheet: IProductSheet;
  language: string;
  showMore: boolean;
  setShowMore: (showMore: boolean) => void;
  setLinkedFluidCount: (count: number) => void;
  setUnlinkedFluidCount: (count: number) => void;
}) => {
  const { t } = useTranslation();
  const [sheet, setSheet] = useState(props.sheet);
  let rowsWithLinks = sheet?.productCollection?.filter(row => row?.link !== null) || [];
  let rowsWithoutLinks = sheet?.productCollection?.filter(row => row?.link === null) || [];
  props.setLinkedFluidCount(rowsWithLinks?.length || 0);
  props.setUnlinkedFluidCount(rowsWithoutLinks?.length || 0);

  useEffect(() => {
    console.log(
      '🚀 ~ file: SheetTable.tsx ~ line 32 ~ props.sheet.productCollection.map ~ props.sheet',
      props.sheet
    );

    setSheet(props.sheet);

    document?.getElementById('sheetTableId')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
    props.setLinkedFluidCount(rowsWithLinks?.length || 0);
    props.setUnlinkedFluidCount(rowsWithoutLinks?.length || 0);
    props.setShowMore(rowsWithLinks.length === 0 && rowsWithoutLinks.length > 0);
  }, [props.sheet, props.language,rowsWithLinks?.length]);

  const columns: IColumn[] = [
    { key: 'productName', check: (row: IProduct) => row?.productName !== null, isLink: true },
    { key: 'type30', check: (row: IProduct) => row?.type30 !== false },
    { key: 'type40', check: (row:IProduct) => row?.type40 !== false },
    { key: 'type80', check: (row: IProduct) => row?.type80 !== false },
    { key: 'type90', check: (row: IProduct) => row?.type90 !== false },
    { key: 'type0W', check: (row: IProduct) => row?.type0W !== false },
    { key: 'type0W20', check: (row: IProduct) => row?.type0W20 !== false },
    { key: 'type0W30', check: (row: IProduct) => row?.type0W30 !== false },
    { key: 'type0W40', check: (row: IProduct) => row?.type0W40 !== false },
    { key: 'type5W20', check: (row: IProduct) => row?.type5W20 !== false },
    { key: 'type5W30', check: (row: IProduct) => row?.type5W30 !== false },
    { key: 'type5W40', check: (row: IProduct) => row?.type5W40 !== false },
    { key: 'type5W50', check: (row: IProduct) => row?.type5W50 !== false },
    { key: 'type10W30', check: (row: IProduct) => row?.type10W30 !== false },
    { key: 'type10W40', check: (row: IProduct) => row?.type10W40 !== false },
    { key: 'type10W60', check: (row: IProduct) => row?.type10W60 !== false },
    { key: 'type15W40', check: (row: IProduct) => row?.type15W40 !== false },
    { key: 'type20W50', check: (row: IProduct) => row?.type20W50 !== false },
    { key: 'type75W', check: (row: IProduct) => row?.type75W !== false },
    { key: 'type75W80', check: (row: IProduct) => row?.type75W80 !== false },
    { key: 'type75W85', check: (row: IProduct) => row?.type75W85 !== false },
    { key: 'type75W90', check: (row: IProduct) => row?.type75W90 !== false },
    { key: 'type80W', check: (row: IProduct) => row?.type80W !== false },
    { key: 'type80W90', check: (row: IProduct) => row?.type80W90 !== false },
    { key: 'type80W85', check: (row: IProduct) => row?.type80W85 !== false },
    { key: 'type85W90', check: (row: IProduct) => row?.type85W90 !== false },
    { key: 'description', check: (row: IProduct) => row?.description !== null },
  ];

  return (
    <div>

      {sheet?.productCollection?.length > 0 && (
        <div id='sheetTableId' className='wb-padding-s sheetTableDesktop'>
          <table className='wb-table wb-table--striped sheetTable'>
            <thead className='tableHeader'>
              <tr>
                {columns.map(column => 
                  sheet?.productCollection?.some(column.check) && (
                    <th scope='col' key={column.key}>{t(TABLE_HEADER[column.key].value)}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {rowsWithLinks.map((row, key) => (
                <tr key={key}>
                  {columns.map(column => 
                    sheet?.productCollection?.some(column.check) && (
                      <td key={column.key}>
                        {column.isLink && row?.link ? (
                          <a
                            target='_blank'
                            className='wb-link dt-link'
                            href={row?.link} rel="noreferrer"
                          >
                            {row[column.key]}
                          </a>
                        ) : column.key.startsWith('type') && row[column.key] ? (
                          <WbIcon name='checkmark' className='iconCheck'></WbIcon>
                        ) : (
                          row[column.key]
                        )}
                      </td>
                    )
                  )}
                </tr>
              ))}
              {props.showMore && rowsWithoutLinks.map((row, key) => (
                <tr key={key}>
                  {columns.map(column => 
                    sheet?.productCollection?.some(column.check) && (
                      <td key={column.key}>
                        {column.isLink && row?.link ? (
                          <a
                            target='_blank'
                            className='wb-link'
                            href={row?.link} rel="noreferrer"
                          >
                            {row[column.key]}
                          </a>
                        ) : column.key.startsWith('type') && row[column.key] ? (
                          <WbIcon name='checkmark' className='iconCheck'></WbIcon>
                        ) : (
                          row[column.key]
                        )}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}







      {/* 

      {sheet?.productCollection?.length > 0 && (
        <div id='sheetTableId' className='wb-padding-s sheetTableDesktop'>
          <table className='wb-table wb-table--striped sheetTable'>
            <thead className='tableHeader'>
              <tr>
                {sheet?.productCollection?.some(
                  (i) => i?.productName !== null
                ) && (
                  <th scope='col'>{t(TABLE_HEADER['productName'].value)}</th>
                )}
                {sheet?.productCollection?.some((i) => i?.type30 !== false) && (
                  <th scope='col'>{t(TABLE_HEADER['type30'].value)}</th>
                )}
                {sheet?.productCollection?.some((i) => i?.type40 !== false) && (
                  <th scope='col'>{t(TABLE_HEADER['type40'].value)}</th>
                )}
                {sheet?.productCollection?.some((i) => i?.type80 !== false) && (
                  <th scope='col'>{t(TABLE_HEADER['type80'].value)}</th>
                )}
                {sheet?.productCollection?.some((i) => i?.type90 !== false) && (
                  <th scope='col'>{t(TABLE_HEADER['type90'].value)}</th>
                )}
                {sheet?.productCollection?.some((i) => i?.type0W !== false) && (
                  <th scope='col'>{t(TABLE_HEADER['type0W'].value)}</th>
                )}
                {sheet?.productCollection?.some(
                  (i) => i?.type0W20 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type0W20'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type0W30 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type0W30'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type0W40 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type0W40'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type5W20 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type5W20'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type5W30 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type5W30'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type5W40 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type5W40'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type5W50 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type5W50'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type10W30 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type10W30'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type10W40 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type10W40'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type10W60 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type10W60'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type15W40 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type15W40'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type20W50 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type20W50'].value)}</th>}
                {sheet?.productCollection?.some((i) => i.type75W !== false) && (
                  <th scope='col'>{t(TABLE_HEADER['type75W'].value)}</th>
                )}
                {sheet?.productCollection?.some(
                  (i) => i?.type75W80 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type75W80'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type75W85 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type75W85'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type75W90 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type75W90'].value)}</th>}
                {sheet?.productCollection?.some((i) => i.type80W !== false) && (
                  <th scope='col'>{t(TABLE_HEADER['type80W'].value)}</th>
                )}
                {sheet?.productCollection?.some(
                  (i) => i?.type80W90 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type80W90'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type80W85 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type80W85'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.type85W90 !== false
                ) && <th scope='col'>{t(TABLE_HEADER['type85W90'].value)}</th>}
                {sheet?.productCollection?.some(
                  (i) => i?.description !== null
                ) && (
                  <th scope='col'>{t(TABLE_HEADER['description'].value)}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {sheet?.productCollection?.map((row, key) => {
                return (
                  <tr key={key}>
                    {row?.link !== null ? (
                      <a
                        target='_blank'
                        className={clsx({
                          ['wb-link dt-link']: row?.link != null,
                        })}
                        href={row?.link}
                      >
                        <td>{row?.productName}</td>
                      </a>
                    ) : (
                      <td>{row?.productName}</td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type30 !== false
                    ) && (
                      <td>
                        {row?.type30 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type40 !== false
                    ) && (
                      <td>
                        {row?.type40 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type80 !== false
                    ) && (
                      <td>
                        {row?.type80 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type90 !== false
                    ) && (
                      <td>
                        {row?.type90 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type0W !== false
                    ) && (
                      <td>
                        {row?.type0W && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type0W20 !== false
                    ) && (
                      <td>
                        {row?.type0W20 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type0W30 !== false
                    ) && (
                      <td>
                        {row?.type0W30 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type0W40 !== false
                    ) && (
                      <td>
                        {row?.type0W40 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type5W20 !== false
                    ) && (
                      <td>
                        {row?.type5W20 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type5W30 !== false
                    ) && (
                      <td>
                        {row?.type5W30 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type5W40 !== false
                    ) && (
                      <td>
                        {row?.type5W40 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type5W50 !== false
                    ) && (
                      <td>
                        {row?.type5W50 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type10W30 !== false
                    ) && (
                      <td>
                        {row?.type10W30 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type10W40 !== false
                    ) && (
                      <td>
                        {row?.type10W40 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type10W60 !== false
                    ) && (
                      <td>
                        {row?.type10W60 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type15W40 !== false
                    ) && (
                      <td>
                        {row?.type15W40 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type20W50 !== false
                    ) && (
                      <td>
                        {row?.type20W50 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type75W !== false
                    ) && (
                      <td>
                        {row?.type75W && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type75W80 !== false
                    ) && (
                      <td>
                        {row?.type75W80 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type75W85 !== false
                    ) && (
                      <td>
                        {row?.type75W85 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type75W90 !== false
                    ) && (
                      <td>
                        {row?.type75W90 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type80W !== false
                    ) && (
                      <td>
                        {row?.type80W && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type80W90 !== false
                    ) && (
                      <td>
                        {row?.type80W90 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type80W85 !== false
                    ) && (
                      <td>
                        {row?.type80W85 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    {sheet?.productCollection?.some(
                      (i) => i?.type85W90 !== false
                    ) && (
                      <td>
                        {row?.type85W90 && (
                          <WbIcon
                            name='checkmark'
                            className='iconCheck'
                          ></WbIcon>
                        )}
                      </td>
                    )}
                    <td>{row?.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )} */}
    </div>
  );
};
