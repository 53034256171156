import { CarSkeleton } from './subcomponent/CarSkeleton/CarSkeleton';

import MBBeVoContentSlider from './subcomponent/MBBeVoContentSlider/MBBeVoContentSlider';
import MBBeVoDesc from './subcomponent/MBBeVoDesc/MBBeVoDesc';
import './MainPage.css';
import { CarSkeletonSmartphone } from './subcomponent/CarSkeletonSmartphone/CarSkeletonSmartphone';
import { CarSkeletonTablet } from './subcomponent/CarSkeletonTablet/CarSkeletonTablet';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

/**
 * Main page (landing page or home page) component.
 *
 * @returns
 */

export function MainPage() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    // Change language based on the current pathname
    const path = location.pathname; // Get the current pathname
    if (path.startsWith('/de') || path === '/de') {
      i18n.changeLanguage('de');
    } else if (path.startsWith('/en') || path === '/en') {
      i18n.changeLanguage('en');
    }

    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, [location.pathname, i18n]); // Add location.pathname and i18n as dependencies
  const hreflang = i18n.language;
  return (
    <>
       <Helmet>
        <title>{t('mainPage.title', { keySeparator: "."})}</title>
        <meta name="description" content={t('mainPage.description' , { keySeparator: "."})} />
        <meta name="keywords" content={t('mainPage.keywords' , { keySeparator: "."})} />
        <meta name="author" content={t('mainPage.author' , { keySeparator: "."})} />
        <meta name="robots" content={t('mainPage.robots' , { keySeparator: "."})} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={t('mainPage.title' , { keySeparator: "."})} />
        <meta property="og:description" content={t('mainPage.description' , { keySeparator: "."})} />
        <link rel="alternate" href={"https://bevo.mercedes-benz-trucks.com/de"} hrefLang="de" />
        <link rel="alternate" href={"https://bevo.mercedes-benz-trucks.com/en"} hrefLang="en" />
        <link rel="canonical" href={"https://bevo.mercedes-benz-trucks.com"} />
      </Helmet>
      {/* Slider for desktop devices > 992px */}
      <CarSkeleton></CarSkeleton>
      {/* Slider for tablet devices > 768px < 992px */}
      <CarSkeletonTablet></CarSkeletonTablet>
      {/* Slider for smartphone devices < 768px */}
      <CarSkeletonSmartphone></CarSkeletonSmartphone>

      <MBBeVoContentSlider></MBBeVoContentSlider>

      {/* MBBeVoDesc */}
      <MBBeVoDesc></MBBeVoDesc>
    </>
  );
}
