/* eslint-disable */
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import IconCard from '../MBBeVoIconCard/MBBeVoIconCard';
import './MBBeVoContentSliderWeb.css';
import { useTranslation, i18n } from 'react-i18next';

// Styling
const useStyles = makeStyles(() => createStyles({}));

export function ContentSlider(props) {
  const content = props.content;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <>
      <div>
        <div className='wb-grid-container'>
          <div className='wb-grid-row'>
            <p className='wb-type-heading-m sectionTitle'>
              <p className='wb-type-heading-m sectionTitle'>
                {t('ChoooseBeVo')}
              </p>
            </p>
            <div
              className='wb-grid-col-mq3-12 wb-grid__zero-padding'
              style={{ width: '75%', margin: 'auto' }}
            >
              <wb-slider
                theme='dark'
                show-arrow-navigation
                show-dot-navigation
                style={{ overflow: 'visible' }}
              >
                {content.map(function (element, index) {
                  return (
                    <div
                      className='wb-slider__item wb-grid-col-mq1-1.7 MBBeVoContentSlider__map'
                      key={index}
                    >
                      <IconCard props={element}></IconCard>
                    </div>
                  );
                })}
              </wb-slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
