import React from 'react';
import { Icon } from '@workbench/core';
import { createStyles, makeStyles } from '@material-ui/core';
import IconCardMobile from '../MBBeVoIconCardMobile/MBBeVoIconCardMobile';
import './MBBeVoContentSliderMobile.css';
import { IMBBeVoContentSlider } from '../../../../interfaces/IMBBeVoContentSlider';

const MBBeVoContentSliderMobile = (props: any) => {
  const content: IMBBeVoContentSlider[] = props.content;

  return (
    <div className='wb-grid-container p'>
      <div className='wb-grid-row'>
        {content.map(function (element, index) {
          return <IconCardMobile props={element}></IconCardMobile>;
        })}
      </div>
    </div>
  );
};

export default MBBeVoContentSliderMobile;
