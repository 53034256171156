import { useState, useEffect } from 'react';
import '../../../globals/style/GlobalStyle.css';
import './SearchOverview.css';
import { getSearchResults } from '../../../utility/FetchAPI';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showPdfInNewTab } from '../../../utility/Helper';
import { closeSearch } from '../../../utility/DialogHelper';

export const SearchOverview = () => {
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const search = (text) => {
    setSearchText(text);

    getSearchResults(text).then((results) => {
      setSearchResult(results.data);
    });
  };

  const searchButton = () => {
    getSearchResults(searchText).then((results) => {
      setSearchResult(results.data);
    });
  };

  const closeAndResetSearch = () => {
    closeSearch();
    setSearchText('');
    setSearchResult(null);
  };

  const selectSheetItem = (sheet) => {
    if (
      sheet.pdfDe === null ||
      sheet.pdfDe === '' ||
      sheet.pdfEn === null ||
      sheet.pdfEn === ''
    ) {
      setSearchText('');
      setSearchResult(null);
    }
    showPdfInNewTab({ sheet, language: i18n.language, navigate });
  };

  return (
    <div
      id='searchContainer'
      className='notVisible searchContainerStyle foreground'
    >
      <div className='inputSearch'>
        <wb-input-control>
          <wb-input>
            <label>{t('SearchLabel')}</label>
            <input
              onChange={(e) => search(e.target.value.toString())}
              className='searchInput'
              type='text'
              value={searchText}
            />
            <wb-input-action>
              <button
                class='wb-round-button wb-round-button--small'
                type='button'
                onClick={() => {
                  setSearchText('');
                  setSearchResult(null);
                }}
              >
                <wb-icon class='wb-round-button__icon' name='close' />
                Clear input
              </button>
            </wb-input-action>
          </wb-input>
        </wb-input-control>
      </div>
      <div class='searchButton'>
        <button
          type='button'
          aria-label='Search Button'
          class='wb-button wb-button--primary wb-button--large wb-button--icon-only'
          onClick={searchButton}
        >
          <wb-icon
            class='wb-button__icon'
            aria-hidden='true'
            name='search'
          ></wb-icon>
          Search
        </button>
      </div>
      <wb-button-control
        variant='tertiary'
        class='backButton wb-type-button-secondary'
        onClick={closeAndResetSearch}
      >
        <button type='button'>{t('BackToOverview')}</button>
      </wb-button-control>
      <div className='textWhite wb-type-label textSpace'>
        {searchText != '' && (
          <p>{`${t('SearchResultPart1')} ${searchResult?.length} ${t(
            'SearchResultPart2'
          )}`}</p>
        )}
      </div>
      <div class='cardContainer'>
        {searchResult?.map((productSheet) => {
          return (
            <div
              class='wb-padding-s searchCard'
              onClick={() => selectSheetItem(productSheet)}
            >
              <wb-card interactive variant='dark-grey'>
                <a class='wb-type-copy-strong'>
                  <div class='wb-type-hint firstColumnHeader'>{t('Sheet')}</div>
                  <div class='wb-type-heading-m firstColumnText'>
                    {productSheet?.sheetNumber}
                  </div>
                  <hr class=' verticalLineCard'></hr>
                  <div class='wb-type-hint secondColumnHeader'>
                    {t('Description')}
                  </div>
                  <div class='wb-type-label secondColumnText'>
                    {i18n.language === 'de'
                      ? productSheet?.titleDe
                      : productSheet?.titleEn}
                  </div>
                  <wb-icon name='chevron-right' class='cardIcon'></wb-icon>
                </a>
              </wb-card>
            </div>
          );
        })}
      </div>
    </div>
  );
};
