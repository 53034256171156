export const LegalNoticeDe = () => {
    return (
        <>
            <h3>Rechtliche Hinweise</h3>
            <br/>
            <div>
                <h4>Urheberrecht</h4>
                <p>
                    Copyright {new Date().getFullYear()}. Daimler Truck AG. Alle Rechte vorbehalten. Alle Texte, Bilder, Graphiken, Ton-,
                    Video- und Animationsdateien sowie ihre Arrangements unterliegen dem Urheberrecht und anderen
                    Gesetzen zum Schutz geistigen Eigentums. Sie dürfen weder für Handelszwecke oder zur Weitergabe
                    kopiert, noch verändert und auf anderen Web-Sites verwendet werden. Einige Internet-Seiten der
                    Daimler Truck AG enthalten auch Material, das dem Urheberrecht derjenigen unterliegt, die dieses zur
                    Verfügung gestellt haben.
                </p>
                <h4>Handelsmarken</h4>
                <p>
                    Wo nicht anders angegeben, sind alle auf den Internet-Seiten der Daimler Truck AG genannten Marken
                    gesetzlich geschützte Warenzeichen der Daimler Truck AG, dies gilt insbesondere für die Modellnamen
                    sowie alle Logos und Embleme des Unternehmens.
                </p>
                <h4>Lizenzrechte</h4>
                <p>
                    Die Daimler Truck AG möchte Ihnen ein innovatives und informatives Internet-Programm anbieten. Wir
                    hoffen deshalb, dass Sie sich über unsere kreative Gestaltung genauso freuen wie wir. Wir bitten Sie
                    aber dennoch um Verständnis dafür, dass die Daimler Truck AG ihr geistiges Eigentum, einschließlich
                    Patente, Handelsmarken und Urheberrechte, schützen muss und diese Internet-Seiten keinerlei
                    Lizenzrechte an dem geistigen Eigentum der Daimler Truck AG gewähren können.
                </p>
                <h4>Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG)</h4>
                <p>
                    Die Daimler Truck AG wird nicht an einem Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle im Sinne des deutschen Verbraucherstreitbeilegungsgesetzes (VSBG)
                    teilnehmen und ist hierzu auch nicht verpflichtet.
                </p>
                <h4>Informationen zur Online-Streitbeilegung</h4>
                <p>
                    Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog.
                    „OS-Plattform“) geschaffen. Die OS-Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung
                    von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen.
                    Sie können die OS-Plattform unter dem
                    folgenden Link erreichen:{' '}
                    <a
                        href='https://ec.europa.eu/consumers/odr'
                        target='_blank'
                        className='wb-link wb-link--inline'
                    >
                        http://ec.europa.eu/consumers/odr
                    </a>
                </p>

                <h4>Haftung</h4>
                <p>
                    Die Informationen und Angaben auf diesen Seiten stellen keine Zusicherung oder Garantie dar, sei sie
                    ausdrücklich oder stillschweigend. Sie stellen insbesondere keine stillschweigende Zusage oder
                    Garantie betreffend die Beschaffenheit, die Handelsfähigkeit, die Eignung für bestimmte Zwecke oder
                    den Nichtverstoß gegen Gesetze und Patente dar. Auf unseren Internet-Seiten finden Sie auch Links zu
                    anderen Seiten im Internet. Wir möchten Sie darauf hinweisen, dass wir keinen Einfluss auf die
                    Gestaltung und den Inhalt der Seiten haben, auf die verlinkt wird. Wir können daher auch keine
                    Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der dort bereitgestellten
                    Informationen übernehmen. Vor diesem Hintergrund distanzieren wir uns hiermit von allen Inhalten
                    dieser Seiten. Diese Erklärung gilt für alle auf unseren Internet-Seiten enthaltenen Links zu
                    externen Seiten und deren Inhalte.
                </p>
            </div>
        </>
    );
};
