/* eslint-disable */
import React from 'react';

import { Icon } from '@workbench/core';
import { createStyles, makeStyles } from '@material-ui/core';
import './MBBeVoIconCard.css';
import { WbIcon } from '@workbench/react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Styling
const useStyles = makeStyles(() =>
  createStyles({
    content: {
      color: 'white',
      textAlign: 'center',
    },
  })
);

export default function IconCard(props: any) {
  const classes = useStyles();
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* <div style={{ maxWidth: "160px", maxHeight: "180px", margin: "auto" }}>  */}

      <div className='MBBeVoIconCard__wrapper'>
        <div
          className='MBBeVoIconCard__content cursorPointer'
          onClick={() =>
            i18n.language == 'de'
              ? navigate(`/category${props.props.link}/de`)
              : navigate(`/category${props.props.link}/en`)
          }
        >
          <div className='MBBeVoIconCard__icon-wrapper'>
            <div className='MBBeVoIconCard__icon-content'>
              <WbIcon
                className='MBBeVoIconCard__icon'
                /* name={props.props.icon} */
                src={props.props.icon}
              />
            </div>
          </div>
          <div className='MBBeVoIconCard__text-wrapper'>
            <p className='wb-type-hint MBBeVoIconCard__text-content'>
              {t(props.props.description)}
            </p>
          </div>
          <Link
            to={
              i18n.language == 'de'
                ? `/category${props.props.link}/de`
                : `/category${props.props.link}/en`
            }
          >
            <div>
              <Link
                className='wb-button wb-button--primary wb-button--small MBBeVoIconCard__button-anchor'
                to={
                  i18n.language == 'de'
                    ? `/category${props.props.link}/de`
                    : `/category${props.props.link}/en`
                }
              >
                <WbIcon
                  class='MBBeVoIconCard__button-icon'
                  name={Icon.ChevronRight}
                />
                <p className='wb-type-button'>{t('LearnMore')}</p>
              </Link>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
