import desktopSkeletonImage from '../../../../resource/image/truckDesktop.jpg';
import carSkeletonCover from '../../../../resource/image/skeletonCarPlaceholderTransparent.png';
import './CarSkeleton.css';
import IconNavigation from '../iconNavigation/IconNavigation';
import { useTranslation } from 'react-i18next';

/**
 * Interactive car image component for the main page.
 *
 * @returns
 */
export const CarSkeleton = () => {
  const { i18n } = useTranslation();
  return (
    <div className='VechicleSkeletonDesktop'>
        <div className='VechicleSkeletonDesktop__transition'>
            <img
                className='VechicleSkeletonDesktop__image'
                src={carSkeletonCover}
            ></img>
            <div className='engineNavigatorDesktop'>
                <IconNavigation
                    description='EngineOil'
                    open={true}
                    link={
                        i18n.language === 'de'
                            ? '/category/engine-oil/de'
                            : '/category/engine-oil/en'
                    }
                    position='top'
                ></IconNavigation>
            </div>
            <div className='gearNavigatorDesktop'>
                <IconNavigation
                    description='GearOil'
                    open={true}
                    link={
                        i18n.language === 'de'
                            ? '/category/gear-oil/de'
                            : '/category/gear-oil/en'
                    }
                    position='top'
                ></IconNavigation>
            </div>
            <div className='adBlueNavigatorDesktop'>
                <IconNavigation
                    description='AdBlue'
                    open={true}
                    link={
                        i18n.language === 'de'
                            ? '/category/NOx-reducing-agent/de'
                            : '/category/NOx-reducing-agent/en'
                    }
                    position='bottom'
                ></IconNavigation>
            </div>
            <div className='axleOilNavigatorDesktop'>
                <IconNavigation
                    description= 'Axle-Oil'
                    open={true}
                    link={
                        i18n.language === 'de'
                            ? '/category/Axle-Oil/de'
                            : '/category/Axle-Oil/en'
                    }
                    position='bottom'
                ></IconNavigation>
            </div>
            <div className='coolantNavigatorDesktop'>
                <IconNavigation
                    description='Coolant'
                    open={true}
                    link={
                        i18n.language === 'de'
                            ? '/category/coolant/de'
                            : '/category/coolant/en'
                    }
                    position='bottom'
                ></IconNavigation>
            </div>
            <p className='wb-type-heading-l VechicleSkeletonDesktop__title'>
                Mercedes-Benz Trucks<br></br>Operating Fluids
            </p>
            ;
        </div>
        <div className='VechicleSkeletonDesktop__container'>
            <img
                className='VechicleSkeletonDesktop__image'
                src={desktopSkeletonImage}
            ></img>
        </div>
    </div>
  );
};
