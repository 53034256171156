export interface IProduct {
  productName: string;
  description: string;
  productId: string;
  link: string | null;
  privateLabel: string;
  type30: boolean;
  type40: boolean;
  type50: boolean;
  type80: boolean;
  type90: boolean;
  type0W: boolean;
  type0W20: boolean;
  type0W30: boolean;
  type0W40: boolean;
  type5W20: boolean;
  type5W30: boolean;
  type5W40: boolean;
  type5W50: boolean;
  type10W: boolean;
  type10W30: boolean;
  type10W40: boolean;
  type10W60: boolean;
  type15W40: boolean;
  type20W20: boolean;
  type20W50: boolean;
  type75W: boolean;
  type75W80: boolean;
  type75W85: boolean;
  type75W90: boolean;
  type80W: boolean;
  type80W90: boolean;
  type80W85: boolean;
  type85W90: boolean;
}

export const emptyProductObject = {
  productName: '',
  description: '',
  productId: '',
  link: null,
  privateLabel: '',
  type30: false,
  type40: false,
  type50: false,
  type80: false,
  type90: false,
  type0W: false,
  type0W20: false,
  type0W30: false,
  type0W40: false,
  type5W20: false,
  type5W30: false,
  type5W40: false,
  type5W50: false,
  type10W: false,
  type10W30: false,
  type10W40: false,
  type10W60: false,
  type15W40: false,
  type20W20: false,
  type20W50: false,
  type75W: false,
  type75W80: false,
  type75W85: false,
  type75W90: false,
  type80W: false,
  type80W90: false,
  type80W85: false,
  type85W90: false,
};
