import { DUITheme } from '@daimler/material-ui-theme';
import { createMuiTheme } from '@material-ui/core';

// Workbench Colors
// https://workbench.mercedes-benz.com/foundation/colours
export const Colors = {
  Highlight: '#00adef',
  HighlightHover: '#0088c6',
  HighlightPressed: '#009ed8',
  HighlightOutlineDark: '#4a90e2',
  HighlightOutlineLight: '#57cdf5',

  White: '#FFFFFF',
  Smoke: '#f8f8f8',
  Moon: '#f4f4f4',
  Asphalt: '#e5e5e5',
  Silver: '#bfbfbf',
  Noble: '#999999',
  Boulder: '#767676',
  Gunmetal: '#666666',
  Obsidian: '#333333',
  Mineshaft: '#262626',
  Black: '#000000',
  Ok: '#008a00',
  Caution: '#ffbf00',
  Error: '#e1292b',
  CO2a: '#009e46',
  CO2b: '#67b633',
  CO2c: '#d2d700',
  CO2d: '#ffed00',
  CO2e: '#fdc602',
  CO2f: '#f17e04',
  CO2g: '#eb2220',
};
export const Themes = {
  Primary: (() => {
    let theme = createMuiTheme(DUITheme);
    // Autocomplete component overrides .MuiIconButton-colorPrimary -> !important
    theme.palette.primary.main = `${Colors.White} !important`;
    theme.palette.secondary.main = Colors.Black;
    theme.palette.text.primary = Colors.White;
    theme.palette.text.secondary = Colors.Black;
    theme.palette.type = 'dark';
    theme.palette.background.default = Colors.Black;

    return theme;
  })(),
};
