import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CATEGORY_PROPS } from '../../../globals/Constants';
import { parse } from 'date-format-parse';
import { WbIcon } from '@workbench/react';
import {
  emptyProductSheetObject,
  IProductSheet,
} from '../../../interfaces/IProductSheet';
import { getSheetByNumber } from '../../../utility/FetchAPI';
import { HeaderBanner } from '../headerBanner/HeaderBanner';
import HydrogenHeader from '../../../resource/image/HydrogenHeader.jpg';
import { SheetTable } from './SheetTable';
import { useTranslation, getI18n } from 'react-i18next';
import '../../../globals/style/GlobalStyle.css';
import './SheetOverview.css';
import {parseDate, setCategoryString, showPdfInNewTab} from '../../../utility/Helper';
import createDOMPurify from 'dompurify';
import { closeSearch } from '../../../utility/DialogHelper';
import moment from 'moment';
import { IProduct } from '../../../interfaces/IProduct';
import { useLanguageMappings } from '../../../globals/LanguageMappingsContext';

export const SheetOverview = () => {
  const languageMappings = useLanguageMappings();
  console.log('LanguageMappings:', languageMappings);
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const DOMPurify = createDOMPurify(window);
  let { sheetNumber, language } = useParams<{
    sheetNumber: string;
    language: string;
  }>();
  const [sheet, setSheet] = useState<IProductSheet>(emptyProductSheetObject);
  const [foundProps, setFoundProps]: any = useState(null);
  const [choosenLanguage, setChoosenLanguage] = useState(i18n.language);
  const [linkedFluidCount, setLinkedFluidCount] = useState(0);
  const [unlinkedFluidCount, setUnlinkedFluidCount] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const mapping = languageMappings[i18n.language] || languageMappings['en'];

  const handleShowMoreToggle = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    closeSearch();
    if (sheetNumber != null) {
      getSheetByNumber(sheetNumber).then((results: any) => {
        const loopProps = CATEGORY_PROPS.find(
          (element) =>
            setCategoryString(element.description) ==
            setCategoryString(results.data.category)
        );
        setSheet(results.data);
        setFoundProps(loopProps);

        if (sheetNumber == 'DTFR_16D100') {
          setFoundProps((prevProps: any) => {
            return {
              ...prevProps,
              image: HydrogenHeader, 
            };
          });
          
        }
        // const pdfUrl = results.data[mapping.pdf] || results.data.pdfEn;
        // if (i18n.language == 'de') {
        //   showPdfInNewTab(results.data.pdfDe, i18n.language, navigate);
        // } else {
        //   showPdfInNewTab(results.data.pdfEn, i18n.language, navigate);
        // }
        showPdfInNewTab({ sheet: results.data, language: i18n.language, navigate, mapping });
      });
    }
  }, [sheetNumber, language]);

  return (
    <div>
      <HeaderBanner
        image={foundProps?.image}
        description={foundProps?.description}
      ></HeaderBanner>
      <div
        style={{ marginTop: '120px', marginLeft: '14px', marginRight: '14px' }}
      >
        <h2 className='wb-type-heading-m textWhite spaceTopLarge spaceBottom'>
          {sheet.sheetNumber +
            '   ' + sheet[mapping.title] }
        </h2>
        {sheet.htmlDe === null &&
          sheet.htmlEn === null &&
          sheet.productCollection === null && (
            <p className='wb-type-copy-tertiary textWhite spaceTopLarge spaceBottom'>
              {t('NoProduct')}
            </p>
          )}
        <p className='wb-type-copy-tertiary textWhite spaceTopLarge spaceBottom'>
          { sheet[ mapping.additionalInfo]}
          {/* {i18n.language === 'de'
            ? sheet.additionalInfoDe
            : sheet.additionalInfoEn} */}
        </p>
        <p className='textWhite wb-type-label timeLabel'>
          {t('LastUpdate')}{' '}
          {moment(parseDate(sheet.publishDate)).format(mapping.dateFormat)}
        </p>
      </div>
      <SheetTable sheet={sheet} language={`${language}`} showMore={showMore} setLinkedFluidCount= {setLinkedFluidCount} setUnlinkedFluidCount= {setUnlinkedFluidCount} setShowMore = {setShowMore}></SheetTable>
      {sheet?.htmlDe !== null && sheet?.htmlEn !== null && (
        <div className='htmlContainer'>
          <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(sheet[mapping.html || '' ]),
              }}
            />
          {/* {i18n.language === 'de' ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(sheet?.htmlDe),
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(sheet?.htmlEn),
              }}
            />
          )} */}
          { linkedFluidCount > 0 && unlinkedFluidCount > 0 && (
            <div className='wb-grid-row rowStyle'>
            <div className='wb-grid-col-mq1-12 text-center' style={{ textAlign: 'center' }}>
              <button onClick={handleShowMoreToggle} className='wb-button wb-button--primary wb-button--large'>
                {showMore ? t('ShowLess') : t('ShowMore')}  
                <span style={{ marginLeft: '5px' }}>
                  <WbIcon name={showMore ? 'arrow-up' : 'arrow-down'} className='iconArrow' />
                </span>
              </button>
            </div>
          </div>
          )} 
        </div>
      )}
    </div>
  );
};
