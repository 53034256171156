import { useState, useEffect } from 'react';
import './CategoryTable.css';
import { getSheetNumbersByCategory } from '../../../utility/FetchAPI';
import { WbIcon } from '@workbench/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { showPdfInNewTab } from '../../../utility/Helper';

import { useTranslation } from 'react-i18next';
import {
  IProductSheet,
  emptyProductSheetObject,
} from '../../../interfaces/IProductSheet';
import { useLanguageMappings } from '../../../globals/LanguageMappingsContext';

export const CategoryTable = (props: { description: string }) => {
  const languageMappings = useLanguageMappings();
  console.log('LanguageMappings:', languageMappings);
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [sheetCollection, setSheetCollection] = useState([
    emptyProductSheetObject,
  ]);

  const location = useLocation();
  const mapping = languageMappings[i18n.language] || languageMappings['en'];

  useEffect(() => {
    const description: string = props.description;
    getSheetNumbersByCategory(t(description)).then((result: any) => {
        const sortedSheets  = result.data.sort((itemA: IProductSheet, itemB: IProductSheet) => {
            const sheetNumberA = itemA.sheetNumber;
            const sheetNumberB = itemB.sheetNumber;
            if(sheetNumberA.startsWith('DTFR') && !sheetNumberB.startsWith('DTFR')) {
                return -1;
            }
            if(!sheetNumberA.startsWith('DTFR') && sheetNumberB.startsWith('DTFR')) {
                return 1;
            }
            return sheetNumberA.localeCompare(sheetNumberB);
        });
      setSheetCollection(sortedSheets);
    });
  }, [props.description]);

  const path = (sheet: IProductSheet) => {
    let path = '/';
    if (sheet.pdfDe == null && sheet.pdfEn == null) {
      if (i18n.language == 'de') {
        path = '/sheet/' + sheet.sheetNumber.replaceAll(' ', '_') + '/de';
      } else {
        path = '/sheet/' + sheet.sheetNumber.replaceAll(' ', '_') + '/en';
      }
    } else {
      path = location.pathname;
    }
    return path;
  };

  /*   const headerText = () => {
    sheetCollection!.forEach( (sheet: IProductSheet) => {
      return (
              <th scope='col'>{t(sheet.)}</th>
      )
    })
  } */

  const categoryContent = () => {
    let counter = 0;
    sheetCollection.map((sheet: IProductSheet, index) => {
      counter++;
      return (
        <tr key={index}>
          <th scope='row'>{counter}</th>
          <td>{sheet.sheetNumber}</td>
          <td>{sheet.titleDe}</td>
          <td>
            <button
              type='button'
              className='wb-button wb-button--tertiary wb-button--small'
            >
              <WbIcon
                className='wb-button__icon'
                name='bds/chevron-right/24'
                aria-hidden='true'
              ></WbIcon>
            </button>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <div className='wb-padding-s tableContainer tableDesktop'>
        <table className='wb-table wb-table--striped wb-table--theme-dark'>
          <thead>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>{t('Sheet')}</th>
              <th scope='col'>{t('Description')}</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {' '}
            {sheetCollection.map((sheet: IProductSheet, index) => {
              return (
                <tr key={index}>
                  <th scope='row'>{index + 1}</th>
                  <td>{sheet.sheetNumber}</td>
                  <td>
                    {
                    i18n.language === 'de'
                    ? sheet.titleDe
                    : i18n.language === 'pt'
                    ? sheet.titlePt
                    : sheet.titleEn
                    }
                  </td>
                  <td>
                    <Link
                      onClick={() =>
                        showPdfInNewTab({ sheet, language: i18n.language, navigate, mapping })
                      }
                      to={path(sheet)}
                      type='button'
                      className='wb-button wb-button--tertiary wb-button--small'
                    >
                      <WbIcon
                        className='wb-button__icon'
                        name='bds/chevron-right/24'
                        aria-hidden='true'
                      ></WbIcon>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
