export const LegalNoticeEn = () => {
    return (
        <>
            <h3>Legal Notice</h3>
            <br/>
            <div>
                <h4>Copyrights</h4>
                <p>
                    Copyright {new Date().getFullYear()}. Daimler Truck AG. All rights reserved. All text, images, graphics, sound files,
                    video files and animation files and their arrangement are subject to copyright and other
                    intellectual property laws. They may not be copied, either for commercial purposes or for
                    redistribution, nor may they be modified and used on other websites. Some Daimler Truck AG webpages
                    also include material that is subject to the copyright of those parties who have made such material
                    available.
                </p>
                <h4>Trademarks</h4>
                <p>
                    Unless otherwise stipulated, all brand names featured on the pages of Daimler Truck AG websites are
                    legally protected trademarks belonging to Daimler Truck AG. This applies, in particular, to the
                    model names and all corporate logos and emblems.
                </p>
                <h4>Licenses</h4>
                <p>
                    Daimler Truck AG has sought to bring you an innovative and informative internet site, and we hope
                    that you will be as enthusiastic about our creative effort as we are. Please appreciate, however,
                    that Daimler Truck AG has to protect its intellectual property, including patents, trademarks and
                    copyrights, and that these internet pages cannot be construed as granting any license rights to
                    Daimler Truck AG’s intellectual property.
                </p>
                <p>
                    <h4>Information according to § 36 German Consumer Dispute Resolution Act (VSBG)</h4>
                    Daimler Truck AG will not participate in a dispute settlement proceeding in front of a consumer
                    arbitration board according to the German Consumer Dispute Resolution Act (VSBG) and is not obliged
                    to do so.
                </p>
                <p>
                    <h4> Information about online dispute resolution</h4>
                    The European Commission has established an internet platform for online dispute resolution (so
                    called “ODR platform”). The ODR platform is a point of entry for out-of-court resolutions relating
                    to contractual obligations of online sales contracts. You can get to the ODR platform by following
                    the link:
                    {' '}
                    <a
                        href='https://ec.europa.eu/consumers/odr'
                        target='_blank'
                        className='wb-link wb-link--inline'
                    >
                        http://ec.europa.eu/consumers/odr
                    </a>
                </p>

                <h4>Liability</h4>
                <p>
                    The information provided and the statements made on these pages do not constitute any representation
                    or warranty, either express or implied. In particular, such information is not an implied promise or
                    guarantee in respect of quality, marketability, fitness for any particular purpose, or the
                    non-infringement of laws and patents. Our internet pages also contain links to other internet sites.
                    We would like to point out that we have no influence over the design and content of the linked
                    sites. We therefore accept no liability for the accuracy, completeness or quality of the information
                    provided there, nor do we guarantee that it is up to date. Consequently, we hereby distance
                    ourselves from all content of such sites. This declaration applies to all links to external sites
                    contained on our internet pages and to the content of such sites.
                </p>
            </div>
        </>
    );
};
