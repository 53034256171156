import { Grid, createStyles, Typography, makeStyles } from '@material-ui/core';
import { Themes, Colors } from '../../../../utility/theme';
import tabletSkeletonImage from '../../../../resource/image/truckTablet.jpg';
import vechicleSkeletonCover from '../../../../resource/image/skeletonVechicleTransparentTablet.png';
import clsx from 'clsx';
import './CarSkeletonTablet.css';
import { useMediaQuery } from 'react-responsive';
import IconNavigation from '../iconNavigation/IconNavigation';
import { useTranslation } from 'react-i18next';

/**
 * Interactive car image component for the main page.
 *
 * @returns
 */
export const CarSkeletonTablet = () => {
  const { i18n } = useTranslation();
  return (
    <div className='VechicleSkeletonTablet'>
      <div className='VechicleSkeletonTablet__transition'>
        <img
          className='VechicleSkeletonTablet__image'
          src={vechicleSkeletonCover}
        ></img>
        <div className='engineNavigator'>
          <IconNavigation
            description='EngineOil'
            open={true}
            link={
              i18n.language === 'de'
                ? '/category/engine-oil/de'
                : '/category/engine-oil/en'
            }
            position='top'
          ></IconNavigation>
        </div>
        <div className='gearNavigator'>
          <IconNavigation
            description='GearOil'
            open={true}
            link={
              i18n.language === 'de'
                ? '/category/gear-oil/de'
                : '/category/gear-oil/en'
            }
            position='top'
          ></IconNavigation>
        </div>
        <div className='adBlueNavigator'>
          <IconNavigation
            description='AdBlue'
            open={true}
            link={
              i18n.language === 'de'
                ? '/category/NOx-reducing-agent/de'
                : '/category/NOx-reducing-agent/en'
            }
            position='bottom'
          ></IconNavigation>
        </div>
        <div className='coolantNavigator'>
          <IconNavigation
            description='Coolant'
            open={true}
            link={
              i18n.language === 'de'
                ? '/category/coolant/de'
                : '/category/coolant/en'
            }
            position='bottom'
          ></IconNavigation>
        </div>
        <p className='wb-type-heading-l VechicleSkeletonTablet__title'>
          Mercedes-Benz Trucks<br></br>Operating Fluids
        </p>
        ;
      </div>
      <div className='VechicleSkeletonTablet__container'>
        <img
          className='VechicleSkeletonTablet__image'
          src={tabletSkeletonImage}
        ></img>
      </div>
    </div>
  );
};
