import React, { createContext, useContext } from 'react';

type LanguageMapping = {
    title: string;
    additionalInfo: string;
    html: string;
    pdf: string;
    dateFormat: string;
  };
  
  type LanguageMappings = {
    [key: string]: LanguageMapping;
  };
export const LanguageMappingsContext = createContext<LanguageMappings | null>(null);

export const useLanguageMappings = () => {
  const context = useContext(LanguageMappingsContext);
  if (!context) {
    throw new Error('useLanguageMappings must be used within a LanguageMappingsProvider');
  }
  return context;
};