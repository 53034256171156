import { useEffect } from 'react';
import './Contact.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendContact } from '../../../utility/FetchAPI';
import { emptyContactObject } from '../../../interfaces/IContact';
import useCountDown from 'react-countdown-hook';
import { useNavigate } from 'react-router-dom';
import isbot from 'isbot';

export const Contact = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const initialTime = 9000;
  const interval = 1000;
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(
    initialTime,
    interval
  );

  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [initialState, setInitialState] = useState(true);
  const [isBot, setIsBot] = useState(false);

  useEffect(() => {
    checkInput();
    setIsBot(isbot(navigator.userAgent));
  });

  const startCountdown = () => {
    reset();
    start();
  };

  const clearForm = () => {
    setEmailSent(true);
    setInitialState(true);
    setEmail('');
    setTitle('');
    setMessage('');
    setErrorEmail(false);
    setErrorTitle(false);
    setErrorMessage(false);
    startCountdown();
    let interval = setInterval(() => {
      clearInterval(interval);
      navigate('/');
    }, initialTime);
  };

  const sendMail = () => {
    checkInput();
    if (errorEmail === true || errorTitle === true || errorMessage === true) {
      setInitialState(false);
    } else {
      let contactData = emptyContactObject;
      contactData.email = email;
      contactData.title = title;
      contactData.message = message;
	  contactData.locale = i18n.language;
      if (!isBot) {
        let data = sendContact(contactData);
        data.then((response) => {
          if ((response.data = 'success')) {
            clearForm();
          }
        });
      } else {
        clearForm();
      }
    }
  };

  const checkInput = () => {
    validateEmail(email) ? setErrorEmail(false) : setErrorEmail(true);
    title === '' || null ? setErrorTitle(true) : setErrorTitle(false);
    message === '' || null ? setErrorMessage(true) : setErrorMessage(false);
  };

  const addEmail = (value) => {
    setEmail(value);
    validateEmail(email) ? setErrorEmail(false) : setErrorEmail(true);
  };

  const addTitle = (value) => {
    setTitle(value);
    title === '' || null ? setErrorTitle(true) : setErrorTitle(false);
  };

  const addMessage = (value) => {
    setMessage(value);
    message === '' || null ? setErrorMessage(true) : setErrorMessage(false);
  };

  const validateEmail = (email) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className='contactContainer'>
      {/* <div>IS BOT? {isBot ? 'true' : 'false'}</div> */}
      <p className='wb-type-heading-l'>{t('WelcomeToSupport')}</p>
      <br />
      <br />
      <div className='wb-grid-container wb-type-label formContainer'>
        <div className='wb-grid-row rowStyle'>
          <div className='wb-grid-col-mq1-2 contactLabels'>{t('Email')}</div>
          <div className='wb-grid-col-mq1-10 mobileContact'>
            <wb-input-control error={!initialState && errorEmail}>
              <wb-input>
                <label>{t('Email')}</label>
                <input
                  aria-labelledby='EmailError'
                  type='email'
                  onChange={(e) => addEmail(e.target.value)}
                  value={email}
                />
              </wb-input>
            </wb-input-control>
            {!initialState && errorEmail && (
              <p className='wb-type-hint-strong error'>{t('ErrorEmail')}</p>
            )}
          </div>
        </div>
        <div className='wb-grid-row rowStyle'>
          <div className='wb-grid-col-mq1-2 contactLabels'>{t('Title')}</div>
          <div className='wb-grid-col-mq1-10 mobileContact'>
            <wb-input-control error={!initialState && errorTitle}>
              <wb-input>
                <label>{t('Title')}</label>
                <input
                  aria-labelledby='TitleError'
                  type='text'
                  onChange={(e) => addTitle(e.target.value)}
                  value={title}
                />
              </wb-input>
            </wb-input-control>
            {!initialState && errorTitle && (
              <p className='wb-type-hint-strong error'>{t('ErrorTitle')}</p>
            )}
          </div>
        </div>

        <div className='wb-grid-row rowStyle'>
          <div className='wb-grid-col-mq1-2 contactLabels'>{t('Message')}</div>
          <div className='wb-grid-col-mq1-10 mobileContact'>
            <wb-input-control error={!initialState && errorMessage}>
              <wb-input>
                <label>{t('Message')}</label>
                <textarea
                  aria-labelledby='MessageError'
                  value={message}
                  onChange={(e) => addMessage(e.target.value)}
                ></textarea>
              </wb-input>
            </wb-input-control>
            {!initialState && errorMessage && (
              <p className='wb-type-hint-strong error'>{t('ErrorMessage')}</p>
            )}
          </div>
          {/* Honey pot */}
          <div>
            <input
              type='checkbox'
              name='dataPrivacy'
              onChange={(e) => setIsBot(e.target.value)}
              value='1'
			  className='dataPrivacy'
              tabindex='-1'
              autocomplete='off'
            ></input>
          </div>
        </div>

        <div className='wb-grid-row rowStyle'>
          <div className='wb-grid-col-mq1-12 wb-grid-col-mq3-4 wb-grid-col-mq4-2 mobileContact sendMailBtnStyle'>
            {' '}
            <button
              type='button'
			  className='wb-button wb-button--primary wb-button--large contactButton'
              onClick={sendMail}
            >
              {t('Send')}
            </button>
          </div>
          <div className='wb-grid-col-mq1-10 wb-grid-col-mq3-12 wb-grid-col-mq4-10 mobileContact '>
            {emailSent && (
              <div className='wb-type-copy-secondary feedbackStyle'>
                {`${t('MailFeedbackPartOne')}${timeLeft / 1000}${t(
                  'MailFeedbackPartTwo'
                )}`}
                <a
					className='wb-link wb-link--standalone'
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/');
                  }}
                >
                  {t('Here')}.
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
