import react from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacyStatementEn = () => {

    function showCMP() {
        // @ts-ignore
        window['UC_UI'].showSecondLayer();
    }
  return (
      <div>
          <h3>Privacy Statement</h3>
          <p>The controller as per the EU General Data Protection Regulation (GDPR) is:</p>
          <p>Daimler Truck AG ("We")<br/>Fasanenweg 10<br/>70771 Leinfelden-Echterdingen<br/>Germany</p>
          <p>E-Mail: <a href="mailto:contact@daimlertruck.com">contact@daimlertruck.com</a></p>
          <p><br/>Data Protection Officer:<br/></p>
          <p>Daimler Truck AG<br/>HPC DTF2B<br/>Fasanenweg 10<br/>70771 Leinfelden-Echterdingen<br/>Germany</p>
          <p>E-Mail: <a 
                        href="mailto:dataprivacy@daimlertruck.com">dataprivacy@daimlertruck.com</a></p>

          <p><strong>1. Data Protection</strong></p>
          <p>We appreciate you visiting our website and your interest in the products we offer. Protecting your personal
              data is very important to us. In this Privacy Policy, we explain how we collect your personal information,
              what we do with it, for what purposes and on what legal foundation we do so, and what rights you have on
              that basis. We will also refer you to Daimler's Data Protection Policy:</p>
          <p>
              <a className="txtlink" 
                       href="https://www.daimlertruck.com/dokumente/unternehmen/datenschutz/data-protection-policy-eu.pdf"
                       target="_blank">Data Protection Policy EU (daimlertruck.com)</a>
          </p>
          <p>Our Privacy Statement on the use of our websites and the Daimler Truck AG Data Protection Policy do not
              apply to your activities on the websites of social networks or other providers that can be accessed using
              the links on our websites. Please read the data protection provisions on the websites of those
              providers.</p>
          <p><strong>2. Collection and processing of your personal data</strong></p>
          <p>a. Whenever you visit our websites, we store certain information about the browser and operating system you
              are using; the date and time of your visit; the status of the interaction (e.g. whether you were able to
              access the website or received an error message); the usage of features on the website; any search phrases
              you entered; how often you visit individual websites; the names of the files you access; the amount of
              data transferred; the Web page from which you accessed our website; and the Web page you visited after
              visiting our website, whether by clicking links on our websites or entering a domain directly into the
              input field of the same tab (or window) of the browser in which you have our websites open. In addition,
              we store your IP address and the name of your Internet service provider for seven days. This is for
              security reasons; in particular, to prevent and detect attacks on our websites or attempts at fraud.</p>
          <p>b. We only store other personal data if you provide this data, e.g. as part of a registration, contact
              form, chat, survey, price competition or for the execution of a contract, and even in these cases only
              insofar as this is permitted to us on the basis of a consent given by you or in accordance with the
              applicable legal provisions (see section 7).</p>
          <p>c. You are neither legally nor contractually obligated to share your personal information. However, certain
              features of our websites may depend on the sharing or personal information. If you do not provide your
              personal information in such cases, you may not be able to use those features, or they may be available
              with limited functionality.</p>
          <p><strong>3. Purposes of use</strong></p>
          <p>a. We use the personal information collected during your visit to any of our websites to make using them as
              convenient as possible for you and to protect our IT systems against attacks and other unlawful
              activities.</p>
          <p>b. If you share additional information with us – for example, by filling out a registration form, contact
              form, chat, survey, contest entry or to execute a contract with you – we will use that information for the
              designated purposes, purposes of customer management and – if required – for purposes of processing and
              billing and business transactions within the required scope in each instance.</p>
          <p>c. For other purposes (e.g. display of personalized content or advertising based on your usage behaviour),
              we and, if applicable, selected third parties, use your personal data if and to the extent you give your
              consent through our consent management system. You will find further information and decisionmaking
              options here: <a href="#" onClick={showCMP}>CMP</a></p>
          <p>d. In addition, we use personal data to the extent that we are legally obliged to do so (e.g., storage for
              the fulfilment of commercial or taxrelated retention obligations, release in accordance with official or
              judicial orders, e.g. to a law enforcement authority).</p>
          <p><strong>4. Transfer of Personal Information to Third Parties; Social Plugins; Use of Service
              Providers</strong></p>
          <p>a. Our websites may also contain an offer of third parties. If you click on such an offer, we transfer data
              to the respective provider to the required extent (e.g. information that you have found this offer with us
              and, if applicable, further information that you have already provided on our websites for this
              purpose).</p>
          <p>b. When we use social plug-ins on our websites from social networks such as Facebook and Twitter, we
              integrate them as follows:</p>
          <p>When you visit our websites, the social plug-ins are deactivated, i.e. no data is transmitted to the
              operators of these networks. If you want to use one of the networks, click on the respective social
              plug-in to establish a direct connection to the server of the respective network.</p>
          <p>If you have a user account on the network and are logged in when you activate the social plug-in, the
              network can associate your visit to our websites with your user account. If you want to avoid this, please
              log out of the network before activating the social plug-in. A social network cannot associate a visit to
              other Daimler websites until you have activated an existing social plug-in.</p>
          <p>When you activate a social plug-in, the network transfers the content that becomes available directly to
              your browser, which integrates it into our websites. In this situation, data transmissions can also take
              place that are initiated and controlled by the respective social network. Your connection to a social
              network, the data transfers taking place between the network and your system, and your interactions on
              that platform are governed solely by the privacy policies of that network.</p>
          <p>The social plug-in remains active until you deactivate it or delete your cookies (see section 5.d).</p>
          <p>c. If you click on the link to an offer or activate a social plug-in, personal data may reach providers in
              countries outside the European Economic Area that, from the point of view of the European Union ("EU"),
              may not guarantee an "adequate level of protection" for the processing of personal data in accordance with
              EU standards. Please remember this fact before clicking on a link or activating a social plug-in and
              thereby triggering a transfer of your data.</p>
          <p>d. We also use qualified service providers (e.g., IT service providers, marketing agencies) to operate,
              optimize and secure our websites. We only pass on personal data to the latter insofar as this is necessary
              for the provision and use of the website and its functionalities, for the pursuit of legitimate interests,
              to comply with legal obligations, or insofar as you have consented there to (see section 7).</p>
          <p><strong>5. Cookies</strong></p>
          <p>a. Cookies may be used when you are visiting our websites. Technically, these are so-called HTML cookies
              and similar software tools such as Web/DOM Storage or Local Shared Objects (so-called "Flash cookies"),
              which we collectively refer to as cookies.</p>
          <p>b. Cookies are small files that are stored on your desktop, notebook or mobile device while you visit a
              website. Cookies make it possible, for example, to determine whether there has already been a connection
              between the device and the websites; take into account your preferred language or other settings, offer
              you certain certain functions (e.g. online shop, vehicle configurator) or recognize your usagebased
              interests. Cookies may also contain personal data.</p>
          <p>c. Whether and which cookies are used when you visit our websites depends on which areas and functions of
              our websites you use and whether you agree to the use of cookies that are not technically required in our
              Consent Management System.</p>
          <p>d. The use of cookies also depends on the settings of the web browser you are using (e.g., Microsoft Edge,
              Google Chrome, Apple Safari, Mozilla Firefox). Most web browsers are preset to automatically accept
              certain types of cookies; however, you can usually change this setting. You can delete stored cookies at
              any time. Web/DOM storage and local shared objects can be deleted separately. You can find out how this
              works in the browser or device you are using in the manual of the learner.</p>
          <p>e. The consent to, and rejection or deletion of, cookies are tied to the device and also to the respective
              web browser you use. If you use multiple devices or web browsers, you can make decisions or settings
              differently.</p>
          <p>f. If you decide against the use of cookies or delete them, you may not have access to all functions of our
              websites or individual functions may be limited.</p>
          <p><strong>6. Security</strong></p>
          <p>We take technical and organizational security measures in order to protect your information managed by us
              from being tampered with, lost, destroyed or accessed by authorized individuals. We are continuously
              improving our security measures in line with technological advancements.</p>
          <p><strong>7. Legal Foundations for Processing</strong></p>
          <p>a. If you have given us your consent to process your personal information, then that is the legal
              foundation for processing it (Art. 6, para. 1, letter a, of the EU's General Data Protection Regulation,
              or GDPR).</p>
          <p>b. Art. 6, para. 1, letter b, of the GDPR is the legal basis for processing personal information for the
              purpose of entering into a contract or performing a contract with you.</p>
          <p>c. If processing your personal information is required to fulfill our legal obligations (e.g. data
              retention), we are authorized to do so by Art. 6, para. 2, letter c, of the GDPR.</p>
          <p>d. Furthermore, we process personal information for purposes of protecting our legitimate interests as well
              as the interests of third parties in accordance with Art. 6, para. 1, letter f of the GDPR. Examples of
              such legitimate interests include maintaining the functionality of our IT systems as well as the (direct)
              marketing of our products and services (to the extent not covered by your consent) and those of third
              parties and the legally required documentation of business contacts. As part of the consideration of
              interests required in each case, we take into account various aspects, in particular the type of personal
              information, the purpose of processing, the circumstances of processing and your interest in the
              confidentiality of your personal information.</p>
          <p><strong>8. Deleting your personal data</strong></p>
          <p>Your IP address and the name of your Internet service provider, which we store for security reasons, are
              deleted after seven days. Moreover, we delete your personal information as soon as the purpose for which
              it was collected and processed has been fulfilled. Beyond this time period, data storage only takes place
              to the extent made necessary by the legislation, regulations or other legal provisions to which we are
              subject in the EU or by legal provisions in third-party countries if these have an appropriate level of
              data protection. Should it not be possible to delete data in individual cases, the relevant personal data
              are flagged to restrict their further processing.</p>
          <p><strong>9. Rights of the data subject</strong></p>
          <p>a. As a data subject affected by data processing, you have the right to information (Section 15 GDPR),
              Correction (Section 16 GDPR), Deletion (Section 17 GDPR), Restricted processing (Section18 GDPR) and Data
              Transferability (Section 20 GDPR).</p>
          <p>b. If you have consented to the processing of your personal information by us, you have the right to revoke
              your consent at any time. Your revocation does not affect the legality of the processing of your personal
              information that took place before your consent was revoked. It also has no effect on the continued
              processing of the information on another legal basis, such as to fulfill legal obligations (see section
              titled "Legal Foundation of Processing").</p>
          <table id="table_listed" >
              <tbody>
              <tr id="table_row_dark"
                  >
                  <td ><strong>c. Right to object</strong></td>
              </tr>
              <tr>
                  <td ><u>For reasons relating to your particular situation, you have
                      the right to file an objection at any time to the processing of personal data pertaining to you
                      that is collected under Section 6 Clause (1e) GDPR (data processing in the public interest) or
                      Section 6 Clause 1 f) GDPR (data processing on the basis of a balance of interests). If you file
                      an objection, we will continue to process your personal data only if we can document mandatory,
                      legitimate reasons that outweigh your interests, rights and freedoms, or if processing is for the
                      assertion, exercise or defense of legal claims. To the extent we use your personal data for direct
                      marketing based on legitimate interests, you have the right to object at any time without giving
                      reasons.
                  </u>
                  </td>
              </tr>
              </tbody>
          </table>
          <p>d. We ask you to address your claims or declarations to the following contact address if possible: <a
              href="mailto:contact@daimlertruck.com">contact@daimlertruck.com</a></p>
          <p>e. If you believe that the processing of your personal data violates legal requirements, you have the right
              to lodge a complaint with a competent data protection supervisory authority (Art. 77 GDPR).</p>
          <p><strong>10. Newsletter</strong></p>
          <p>If you subscribe to a newsletter offered on our website, the information provided during registration for
              the newsletter will be used solely for the purpose of mailing the newsletter unless you consent to its use
              for additional purposes. You may cancel the subscription at any time by using the option to unsubscribe
              contained in the newsletter.</p>
          <p><strong>11. Daimler Truck AG's Central Registration Service </strong></p>
          <p>With the Central Registration Service offered by Daimler Truck AG, you can sign up for every website and
              application belonging to the Daimler Group and its brands that are connected to the service. The
              applicable terms of use contain specific data protection provisions. Those terms of use can be found on
              the registration pages of affiliated websites and applications.</p>
          <p><strong>12. Data transmission to recipients outside the European Economic Area</strong></p>
          <p>a. When using service providers (see section 4. d.) and passing on data to third parties based on you
              consent (see section 3.c.), personal data may be provided to recipients in countries outside the European
              Union ("EU"), Iceland, Liechtenstein and Norway (= European Economic Area) are transferred and processed
              there, in particular USA, India.</p>
          <p>b. In the following countries, from the EU's point of view, there is an adequate level of personal data
              protection (so-called "adequacy"), in compliance with EU standards: Andorra, Argentina, Canada (limited),
              Faroe Islands, Guernsey, Israel, Isle of Man, Japan, Jersey, New Zealand, Switzerland, Uruguay. We agree
              with recipients in other countries on the use of EU standard contractual clauses, binding corporate rules
              or other applicable instruments (if any) to create an "adequate level of protection" according to legal
              requirements. For more information, please use the contact details given in section 9.d. above.</p>
          <p>Last update: January 2022</p>
      </div>
  );
};
