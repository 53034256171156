/* eslint-disable */
import React from 'react';

import { Icon } from '@workbench/core';
import { createStyles, makeStyles } from '@material-ui/core';
import './MBBeVoIconCardMobile.css';
import { WbIcon } from '@workbench/react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Styling
const useStyles = makeStyles(() =>
  createStyles({
    content: {
      color: 'white',
      textAlign: 'center',
    },
  })
);

export default function IconCard(props: any) {
  const classes = useStyles();
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* <div style={{ maxWidth: "160px", maxHeight: "180px", margin: "auto" }}>  */}

      <div className='wb-grid-col-mq1-12 wb-grid-col-mq1-12 wb-grid-col-mq3-4'>
        <div>
          <div className='MBBeVoIconCard__mobile-icon-wrapper'>
            <div
              className='MBBeVoIconCard__mobile-icon-content cursorPointer'
              onClick={() =>
                i18n.language == 'de'
                  ? navigate(`/category${props.props.link}/de`)
                  : navigate(`/category${props.props.link}/en`)
              }
            >
              <WbIcon
                className='MBBeVoIconCard__mobile-icon'
                src={props.props.icon}
              />
            </div>
          </div>
          <div className='MBBeVoIconCard__mobile-text-wrapper'>
            <p className='wb-type-hint MBBeVoIconCard__mobile-text-content'>
              {t(props.props.description)}
            </p>
          </div>
          <Link
            to={
              i18n.language == 'de'
                ? `/category${props.props.link}/de`
                : `/category${props.props.link}/en`
            }
          >
            <div>
              <Link
                className='wb-button wb-button--primary wb-button--small MBBeVoIconCard__mobile-button-anchor'
                to={
                  i18n.language == 'de'
                    ? `/category${props.props.link}/de`
                    : `/category${props.props.link}/en`
                }
              >
                <WbIcon
                  class='MBBeVoIconCard__mobile-button-icon'
                  name={Icon.ChevronRight}
                />
                <span className='wb-type-button'>{t('LearnMore')}</span>
              </Link>
            </div>
          </Link>
        </div>
      </div>
      <hr className='hrIconLine' />
    </>
  );
}
