import { Grid, createStyles, Typography, makeStyles } from '@material-ui/core';
import carSkeletonImage from '../../../../resource/image/skeletonCarPlaceholder.png';
import smartphoneSkeletonImage from '../../../../resource/image/truckSmartphone.jpg';
import vechicleSkeletonCover from '../../../../resource/image/skeletonVechicleTransparentSmartphone.png';
import clsx from 'clsx';
import './CarSkeletonSmartphone.css';
import { useMediaQuery } from 'react-responsive';
import IconNavigation from '../iconNavigation/IconNavigation';
import { useTranslation } from 'react-i18next';

/**
 * Interactive car image component for the main page.
 *
 * @returns
 */
export const CarSkeletonSmartphone = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className='VechicleSkeletonSmartphone'>
        <div className='VechicleSkeletonSmartphone__transition'>
            <img
                className='VechicleSkeletonSmartphone__image'
                src={vechicleSkeletonCover}
            ></img>
            <div className='engineNavigatorSmartphone'>
                <IconNavigation
                    description='EngineOil'
                    open={false}
                    link={
                        i18n.language === 'de'
                            ? '/category/engine-oil/de'
                            : '/category/engine-oil/en'
                    }
                    position='top'
                ></IconNavigation>
            </div>
            <div className='gearNavigatorSmartphone'>
                <IconNavigation
                    description='GearOil'
                    open={false}
                    link={
                        i18n.language === 'de'
                            ? '/category/gear-oil/de'
                            : '/category/gear-oil/en'
                    }
                    position='top'
                ></IconNavigation>
            </div>
            <div className='adBlueNavigatorSmartphone'>
                <IconNavigation
                    description='AdBlue'
                    open={false}
                    link={
                        i18n.language === 'de'
                            ? '/category/NOx-reducing-agent/de'
                            : '/category/NOx-reducing-agent/en'
                    }
                    position='top'
                ></IconNavigation>
            </div>
            <div className='axleOilNavigatorSmartphone'>
                <IconNavigation
                    description='Axle Oil'
                    open={false}
                    link={
                        i18n.language === 'de'
                            ? '/category/Axle-Oil/de'
                            : '/category/Axle-Oil/en'
                    }
                    position='top'
                ></IconNavigation>
            </div>
            <div className='coolantNavigatorSmartphone'>
                <IconNavigation
                    description='Coolant'
                    open={false}
                    link={
                        i18n.language === 'de'
                            ? '/category/coolant/de'
                            : '/category/coolant/en'
                    }
                    position='bottom'
                ></IconNavigation>
            </div>
            <p className='wb-type-heading-l VechicleSkeletonSmartphone__title'>
                Mercedes-Benz Trucks<br></br>Operating Fluids
            </p>
            ;
        </div>
        <div className='VechicleSkeletonSmartphone__container'>
            <img
                className='VechicleSkeletonSmartphone__image'
                src={smartphoneSkeletonImage}
            ></img>
        </div>
    </div>
  );
};
