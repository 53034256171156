import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { parse } from 'date-format-parse';

import { WbIcon } from '@workbench/react';
import {
  emptyProductSheetObject,
  IProductSheet,
} from '../../interfaces/IProductSheet';
import { getProductsById } from '../../utility/FetchAPI';
import { SheetTable } from '../category/sheetPage/SheetTable';
import { useTranslation } from 'react-i18next';
import './QrOverview.css';
import moment from 'moment';
import { emptyProductObject, IProduct } from '../../interfaces/IProduct';

export const QrOverview = () => {
  //Get data in Use Effect
  const [productSheet, setProductSheet] = useState(emptyProductSheetObject);
  const [sheetNumbers, setSheetNumbers]: any = useState([]);
  const [foundProductCollection, setFoundProductColletion] = useState([
    emptyProductObject,
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [linkedFluidCount, setLinkedFluidCount] = useState(0);
  const [unlinkedFluidCount, setUnlinkedFluidCount] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const { productId } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let numbers: string[] = [];
    let resultSheet = emptyProductSheetObject;
    getProductsById(productId!).then((result: any) => {
      console.log(
        '🚀 ~ file: qrOverview.tsx ~ line 33 ~ getProductsById ~ result.data[0]',
        result.data[0]
      );
      if (typeof result.data[0] !== 'undefined') {
        setProductSheet(result.data[0]);

        resultSheet = result.data[0];
        result.data.map((productSheet: any) => {
          numbers.push(productSheet.sheetNumber);
          numbers.sort((a, b) => (a < b ? -1 : 1));
          setSheetNumbers(numbers);
          let foundProducts = productSheet.productCollection.filter(
            (product: any) => product.productId === productId
          );
          foundProductCollection.push(...foundProducts);
        });

        resultSheet.productCollection = [
          foundProductCollection[foundProductCollection.length - 1],
        ];
        setProductSheet(resultSheet);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <div className='qrPage'>
      {isLoading === false ? (
        <div>
          {sheetNumbers.length < 1 ? (
            <h2 className='textWhite wb-type-heading-l qrTitle'>
              {t('ProductNotApproved')}
            </h2>
          ) : (
            <div>
              <h2 className='textWhite wb-type-heading-l qrTitle'>
                {t('ProductApproval')}
              </h2>

              <div>
                <div className='qrLinkColletion'>
                  <p className='textWhite wb-type-copy-strong linkText'>
                    {' '}
                    {t('Category')}: {t(productSheet.category)}{' '}
                  </p>
                </div>
                <div className='qrTableContainer'>
                  <p className='textWhite wb-type-label timeLabel'>
                    {t('LastUpdate')}{' '}
                    {i18n.language === 'de'
                      ? moment(
                          parse(
                            productSheet.publishDate
                              .toString()
                              .replaceAll(',', ''),
                            'DD.MM.YY HH:mm'
                          ).toString()
                        ).format('DD.MM.YYYY')
                      : moment(
                          parse(
                            productSheet.publishDate
                              .toString()
                              .replaceAll(',', ''),
                            'DD.MM.YY HH:mm'
                          )
                        ).format('MM/DD/YYYY')}
                  </p>
                  <SheetTable
                    sheet={productSheet}
                    language={i18n.language}
                    showMore={showMore} setLinkedFluidCount= {setLinkedFluidCount} setUnlinkedFluidCount= {setUnlinkedFluidCount} setShowMore = {setShowMore}
                  ></SheetTable>
                  <div className='qrSheetCollection'>
                    <span className='textWhite wb-type-label linkText'>
                      {`${t('MbApprovalLabel')}: `}
                    </span>
                    {sheetNumbers.map((sheetNumber: string, index: number) => {
                      return (
                        <>
                          {sheetNumbers.length > index + 1 ? (
                            <span className='textWhite wb-type-label linkText qrSheetNumbers'>
                              {sheetNumber + ' / '}
                            </span>
                          ) : (
                            <span className='textWhite wb-type-label linkText qrSheetNumbers'>
                              {sheetNumber}
                            </span>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
