import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import {
  showProviderTab,
  showLegalNoticeTab,
  showDataPrivacyTab,
  showSettingsTab,
} from '../../utility/DialogHelper';
import { Link } from 'react-router-dom';

// Styling
const useStyles = makeStyles(() =>
  createStyles({
    verticalLine: {
      marginTop: '48px',
      marginBottom: '24px',
      width: '100%',
      backgroundColor: '#333333',
      color: '#333333',
      borderWidth: 0,
      height: '1px',
    },
    linkText: {
      color: '#bfbfbf',
      fontSize: 'small',
      marginRight: '36px',
      marginBottom: '24px',
    },
    container: {
      height: '80px',
      textAlign: 'center',
    },
  })
);

/**
 * Main page (landing page or home page) component.
 *
 * @returns
 */

export function Footer() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const showDetails = () => {
    document!.getElementById('cookieModelContainer')!.classList.remove('hide');
  };

  return (
    <>
      <hr className={clsx(classes.verticalLine)}></hr>
      <div className='wb-grid-container'>
        <div className={clsx('wb-grid-row', classes.container)}>
          <div className='wb-grid-col-mq1-12'>
            <a
              onClick={showProviderTab}
              className={clsx(
                'wb-link wb-link--standalone showForSmartphone',
                classes.linkText
              )}
            >
              {t('Provider')}
            </a>{' '}
            <a
              onClick={showProviderTab}
              className={clsx(
                'wb-link wb-link--standalone hideForSmartphone',
                classes.linkText
              )}
            >
              {t('ReservedRights')}
            </a>{' '}
            <a
              onClick={showLegalNoticeTab}
              className={clsx('wb-link wb-link--standalone', classes.linkText)}
            >
              {t('LegalNotice')}
            </a>{' '}
            {/*<a*/}
            {/*  onClick={showSettingsTab}*/}
            {/*  className={clsx('wb-link wb-link--standalone', classes.linkText)}*/}
            {/*>*/}
            {/*  {t('Settings')}*/}
            {/*</a>{' '}*/}
            <a
              onClick={showDataPrivacyTab}
              className={clsx('wb-link wb-link--standalone', classes.linkText)}
            >
              {t('PrivacyStatement')}
            </a>{' '}
            <Link
              className={clsx('wb-link wb-link--standalone', classes.linkText)}
              to={'/contact'}
            >
              {t('Contact')}
            </Link>{' '}
          </div>
        </div>
      </div>
    </>
  );
}
