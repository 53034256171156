/* eslint-disable */
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import './HeaderBanner.css';
import categoryCover from '../../../resource/image/CategoryPlaceholderTransparent.png';
import { useTranslation } from 'react-i18next';
import categoryHeaderImage from '../../../resource/image/categoryHeader.jpg';

export function HeaderBanner(props: { image: any; description: string }) {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className='bannerDesktop__transition'>
          <img src={categoryCover} className='banner__image '></img>
          <section className='wb-padding-vertical-s categoryDescription'>
            <div className='text-white'>
              <div className='wb-type-label wb-margin-bottom-xxs text-white description'>
                Mercedes-Benz Trucks Operating Fluids:
              </div>
              <hr className='--wb-color-gunmetal Divider'></hr>
            </div>
            <h1 className='wb-type-heading-l text-white TopCategoryTitle description'>
              {t(props?.description?.toString())}
            </h1>
          </section>
        </div>
        <div className='CarSkeleton__container'>
          <img
            src={props.image == null ? categoryHeaderImage : props.image}
            className='banner__image '
          ></img>
        </div>
      </div>
    </>
  );
}
