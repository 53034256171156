import React, { useEffect } from 'react';
import CategoryList from './categoryList/CategoryList';
import { CategoryTable } from './categoryTable/CategoryTable';
import { HeaderBanner } from './headerBanner/HeaderBanner';
import Prolog from './prolog/Prolog';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { closeCategoriesMenu } from '../../utility/DialogHelper';

export const CategoryOverview = (props: {
  description: string;
  image: any;
}) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    closeCategoriesMenu();
    window.scrollTo(0, 0);
    // Change language based on the current pathname
    const path = location.pathname; // Get the current pathname
    if (path.startsWith('/de') || path === '/de') {
      i18n.changeLanguage('de');
    } else if (path.startsWith('/en') || path === '/en') {
      i18n.changeLanguage('en');
    }
  }, [props.description, props.image, location.pathname, i18n]);
  return (
    <><Helmet>
      <title>{t('categoryOverview.title', { keySeparator: "." })}</title>
      <meta name="description" content={t('categoryOverview.description', { keySeparator: "." })} />
      <meta name="keywords" content={t('mainPage.keywords', { keySeparator: "." })} />
      <meta name="author" content={t('mainPage.author', { keySeparator: "." })} />
      <meta name="robots" content={t('mainPage.robots', { keySeparator: "." })} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:title" content={t('categoryOverview.title', { keySeparator: "." })} />
      <meta property="og:description" content={t('categoryOverview.description', { keySeparator: "." })} />
      <link rel="alternate" href={"https://bevo.mercedes-benz-trucks.com/de"} hrefLang="de" />
      <link rel="alternate" href={"https://bevo.mercedes-benz-trucks.com/en"} hrefLang="en" />
      <link rel="canonical" href={"https://bevo.mercedes-benz-trucks.com"} />
    </Helmet>
    <div>
        <HeaderBanner
          image={props.image}
          description={props.description}
        ></HeaderBanner>
        <Prolog description={props.description}></Prolog>
        <div style={{ marginTop: '115px' }}>
          <CategoryTable description={props.description}></CategoryTable>
        </div>
        <div
          style={{ marginTop: '80px', marginRight: '14px', marginLeft: '14px' }}
        >
          <CategoryList description={props.description}></CategoryList>
        </div>
      </div></>
  );
};
