import './ComingSoon.css';

/**
 * Main page (landing page or home page) component.
 *
 * @returns
 */

export default function ComingSoon() {
  return (
    <div className='wb-grid-container text-color wrapper-coming-soon'>
      <div className='wb-grid-row'>
        <div className='wb-grid-col-mq1-12 wb-grid-col-mq2-12 wb-grid-col-mq3-12 wb-grid-col-mq4-12 wb-grid-col-mq5-12 wb-grid-col-mq6-12 '>
          <div className='wb-grid-container text-color'>
            <div className='wb-grid-row'>
              <div className='wb-grid-col-mq1-12 wb-grid-col-mq2-12 wb-grid-col-mq3-12 wb-grid-col-mq4-12 wb-grid-col-mq5-12 wb-grid-col-mq6-12 '>
                <h2 className='wb-banner-teaser-content__heading wb-type-heading-m'>
                  Coming Soon
                </h2>
                <div className='wb-banner-teaser-content__description wb-type-copy'>
                  <p>
                    This page is under construction. Lorem, ipsum dolor sit amet
                    consectetur adipisicing elit. Similique dignissimos amet
                    placeat itaque blanditiis vitae non cum rem sunt voluptates.
                    Nisi impedit aliquam praesentium iusto eligendi neque amet
                    obcaecati distinctio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
